@import "src/theme";

.listItemContainer {
  display: flex;
  flex-direction: row;
  padding: 15px;
  position: relative;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $gray;
  }
}
.listItemContainerHeader {
  font-size: 24px;
  font-weight: bold;
  padding: 10px 10px 10px 15px;
  border-bottom: solid $darkGray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: thin;
}
.status {
  width: 15px;
  height: 15px;
  min-width: 15px;
  border-radius: 100%;
}
.iconCircle {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #75ff7538;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.listItemTitle {
  max-width: 400px;
  min-width: 400px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $evenDarkerGray;
  font-weight: normal;
}
.listItemDescription {
  max-width: 400px;
  min-width: 400px;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $evenDarkerGray;
  font-weight: normal;
  font-style: italic;
}
.listItemBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
}
.legendHeader {
  font-weight: bold;
  text-align: center;
  font-style: italic;
  font-size: 14px;
  color: $evenDarkerGray;
}
.colorsLegend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $darkGray;
  padding: 5px;
  margin-bottom: 10px;
}
.colorsLegendItemBox {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
.colorsLegendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}
.colorsLegendItemText {
  font-weight: bold;
  color: $evenDarkerGray;
  font-size: 14px;
}
