@import "src/theme";

.dashed {
  border: 2px dashed $blue;
}
.dropZoneContainer {
  margin-top: 40px;
  padding-top: 10px !important;
}
.dropZone {
  text-align: center;
  // padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 0px !important;
  margin-top: 20px;
}

.dropZoneContainer {
  flex: 1;
  overflow: auto;
  gap: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  // width: 100%;
}

.info {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 40px;
}

.button {
  padding: 10px;
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
}

.circularProgressBar {
  flex: 1;
  align-items: flex-end;
}
.learnMoreButton {
  background: white;
  border: none;
  color: #2f6df2;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.howToStep {
  background: #b7cdff;
  margin: 10px 0px;
  padding: 20px;
  border-radius: 10px;
  &:hover {
    background: #6b97f6;
  }
}
.importantHowToStep {
  background: #b7cdff;
  margin: 10px 0px;
  padding: 20px;
  font-weight: bold;
  border-radius: 10px;
  &:hover {
    background: #6b97f6;
  }
}
.supportedDocContainer {
  text-align: left !important;
  width: 800px;
  padding: 10px 20px !important;
  margin-bottom: 20px !important;
  border-radius: 10px;
  background: #f3f3f3;
}
.documentPreview {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
