@import "src/theme";

.iconContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  text-align: center;
}

.container {
  background: $gray;
  max-height: 430px;
  min-height: 150px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
.uploadButton {
  margin-top: 30px;
}
.title {
  font-weight: 700;
  margin: 5px 0px;

  font-size: 28px;
  line-height: 33px;
  text-transform: capitalize;
}
.documentName {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
.item {
  margin: 5px 0px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid black;
  }
  cursor: pointer;
}
.button {
  padding: 10px;
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.docs {
  overflow-y: auto;
  max-height: 300px;
  padding-bottom: 10px;
}
