@import "src/theme";

.container {
  padding: 10px;
  input {
    // margin: 5px;
    border-radius: 7px;
  }
  p {
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    color: $primaryDark;
  }
}

.zipCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: 10px;
}
.list {
  gap: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
}
.listItem {
  display: flex;
  min-height: 42px;
  align-items: center;
  border-radius: 5px;
  padding-left: 7px;
  justify-content: flex-start;
  gap: 20px;

  &:hover {
    background: $lightBlueSecondary;
    transition: all 0.1s linear;
    cursor: pointer;
    .closeIcon {
      display: flex;
    }
  }
}
