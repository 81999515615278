@import "src/theme";

.container {
  background: $gray;
  height: 430px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 15px;
}

.title {
  font-weight: 700;
  margin: 5px 0px;

  font-size: 28px;
  line-height: 33px;
  text-transform: capitalize;
}
.noteDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  white-space: pre-line;
}
.itemContainer {
  margin: 10px 0px !important;
  min-height: 50px;
  animation: fadeIn 0.5s ease-in-out;
  padding-right: 10px !important;
}
.item {
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid $secondaryGray;
  &:hover {
    background: $secondaryGray;
  }
  border-radius: 10px;
}

.itemDealer {
  color: #fff;
  background-color: $lightBlueTertiary;
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid $blue;
  &:hover {
    background: $blue;
  }
  border-radius: 10px;
}
.noteCreatorDealer {
  font-style: italic;
  color: #fff;
  margin-left: auto;
  padding-right: 5px;
}
.noteCreator {
  font-style: italic;
  color: $darkerGray;
  margin-left: auto;
  padding-right: 5px;
}
.notesContainer {
  height: 310px;
}
.notes {
  overflow: auto;
  scrollbar-width: thin;

  height: 100%;
}
.createNoteContainer {
  padding: 10px 0px;
  margin-top: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
