@import "src/theme";

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  padding: 0px 20px;
}

.gridItem {
  box-sizing: border-box;
}
