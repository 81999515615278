@import "src/theme";

.chatContainer {
  top: 180px;
  min-height: 80vh;
  max-height: 80vh;
  box-sizing: border-box;
  position: relative;
  border: 1.5px solid $secondaryGray;
  border-radius: 5px;
  max-width: 350px;
  min-width: 350px;
  margin-right: 14px;
  padding: 18px;
  display: flex;
  flex-direction: column;
}
.chatContainerPage {
  box-sizing: border-box;
  position: relative;
  border: 1.5px solid $secondaryGray;
  border-radius: 5px;
  max-width: 350px;
  min-width: 350px;
  margin-right: 14px;
  padding: 18px;
}
.openChatBackground {
  background: $lightBlue;
}

.chatTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: $blue;
}

.sectionsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 6px;
  gap: 11px;
}
.headerTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: auto;
}

.list {
  gap: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
}
.listContainer {
  p {
    font-weight: 600;
    font-size: 20px;
  }
}
.applicantList {
  li:first-child {
    background: none;
    padding-left: 10px;
    &:hover {
      background: $lightBlueSecondary;
      transition: all 0.1s linear;
      cursor: pointer;
    }
  }
  li:not(:first-child) {
    padding-left: 10px;
  }
}
.listItem {
  display: flex;
  min-height: 42px;
  align-items: center;
  border-radius: 5px;
  padding-left: 7px;
  justify-content: flex-start;
  gap: 20px;

  &:hover {
    background: $lightBlueSecondary;
    transition: all 0.1s linear;
    cursor: pointer;
    .closeIcon {
      display: flex;
    }
  }
}
.closeIcon {
  display: none;
  color: $blue;
  padding-left: 0px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    color: #fff;
    border-radius: 100%;
    display: flex;
    cursor: pointer;
    background: $blue;
  }
}
.divider {
  border-top: 1px solid $darkGray;
}
.text {
  white-space: nowrap;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0px;
}
.openChatHeader {
  gap: 15px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0px 5px;
  background: linear-gradient(90deg, #6b97f6 -25%, #b7cdff 135%);
}

.selectedChatName {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  margin: 10px 0px;
}
.phomeIcon {
  margin-left: auto;
  color: $blue;
  background: #fff;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
}
.noChannelMessage {
  font-size: 14px !important;
  font-weight: normal !important;
  text-align: center;
  margin: 0;
  color: rgb(129, 129, 129);
}
.backIcon {
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  background: none;
  padding: 5px;
  cursor: pointer;
  &:hover {
    border-radius: 50%;
    transition: all 0.1s linear;
    transform: scale(1.03);
  }
}
.sendIcon {
  display: flex;
  justify-content: center;
  background: $primaryGradientColor;
  color: #fff;
  border-radius: 50%;
  border: none;
  padding: 6px;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    transition: all 0.1s linear;
    transform: scale(1.03);
  }
}
.openChat {
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
}
.newMessageContainer {
  margin: 10px 0px;
}
.newMessage {
  padding: 0px 5px;
  background: #fff;
  gap: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover:not(:disabled) {
    box-shadow: 0px 0px 4px -3px black;
  }
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.newMessageTopRadius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

//messages
.message {
  white-space: pre-line;
}
.unseenMessagesTimestamp {
  white-space: nowrap;
}
.warning {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: white;
  padding: 10px;
  color: black;
  border: 1px solid red;
}

.messagesContainer {
  margin: 20px 0px;
  flex: 1 1 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.bubble {
  flex: 1 1 auto;
  clear: both;
} /* clear the floats here on parent */
.bubble p {
  border-radius: 5px;
  padding: 8px;
  margin: 12px 12px;
  max-width: 80%; /* this will make it not exceed 80% and then wrap */
  position: relative;
  transition: background-color 0.5s;
}
.left p {
  background-color: #fff;
  float: left;
} /* floated left */
.right p {
  background-color: $lightBlueTertiary;
  color: #fff;
  float: right;
} /* floated right */
.delivered p {
  background-color: $blue;
}
/* classes below are only for arrows, not relevant */
.left p::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  top: 8px;
  border-top: 4px solid transparent;
  border-right: 8px solid #fff;
  border-bottom: 4px solid transparent;
}
.right p::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -8px;
  bottom: 8px;
  border-top: 4px solid transparent;
  border-left: 8px solid $blue;
  border-bottom: 4px solid transparent;
}

.messageDate {
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;
  bottom: -20px;
  font-weight: 400;
  font-size: 10px;
  margin: 3px 0px;
  line-height: 12px;
  color: $evenDarkerGray;
}

.messageDateLeft {
  left: 0;
}
.messageDateRight {
  right: 0;
}
.messageLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $evenDarkerGray;
  display: flex;
  justify-content: center;
}

.attachment {
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: $evenDarkerGray;
  border: 2px solid $blue;
  border-radius: 15px;
  padding: 10px;
}
.messageAttachmentContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px;
  gap: 5px;
}
.messageAttachment {
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid $blue;
  border-radius: 10px;
  padding: 10px;
  background: white;
  color: #000;
  gap: 5px;
}
.attachments {
  display: flex;
  background: #fff;
  padding: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  gap: 10px;
  overflow-x: auto;
}

.deleteAttachment {
  border-radius: 100%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -11px;
  right: -14px;
  background: white;
  border: none;
  padding: 4px;
  cursor: pointer;
  &:hover {
    transition: all 0.1s linear;
    transform: scale(1.03);
  }
}
.rotatingCircle {
  animation: rotating_circle 1300ms linear infinite normal forwards;
}
@keyframes rotating_circle {
  0% {
    transform: translate(41.5899px, 42.0001px) rotate(0deg);
  }
  100% {
    transform: translate(41.5899px, 42.0001px) rotate(351deg);
  }
}

.switchesContainer {
  display: flex;
  gap: 70px;
  margin-bottom: 5px;
}
