@import "src/theme";
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  position: absolute;
  top: 59.5px;
  right: 60px;
}

.userMenu {
  position: absolute;
  background: white;

  top: 50px;
  right: 0px;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.userMenuItem {
  cursor: pointer;
  padding: 15px;
  &:hover {
    background: $gray;
  }
}
.encodedRoles {
  padding: 4px;
  word-break: break-all;
  font-size: 8px;
}
