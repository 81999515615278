@import "src/theme";
.dialogContainer {
  background: #ffffff;
  padding: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: column;
  overflow: auto;
  word-break: break-word;
  position: absolute;
  // transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  // transform: scale(1);
  animation: fadein 0.5s;

  p {
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    margin: 10px 15px;
  }
}
.blue {
  color: $blue;
}
.default {
  color: $primaryDark;
}
// .title {
//   font-weight: 700;
//   font-size: 28px;
//   line-height: 33px;
//   text-transform: capitalize;
//   margin: 5px 0px;
// }

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}
.dialogContent {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.dialogActions {
  display: flex;
  width: 60%;
  gap: 10px;
  justify-self: flex-end;
}
.end {
  align-self: flex-end;
}
.center {
  align-self: center;
}
.sm {
  max-width: 640px;
}
.lg {
  max-width: 1200px;
}

.xs {
  max-width: 320px;
}
.xs-min {
  max-width: 320px;
  min-width: 280px;
}

.spacing {
  margin: 20px 0px;
}
