@import "src/theme";
:root {
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.stepper {
  display: flex;
}
.stepperPreview {
  display: flex;
  width: 100%;
  height: 100%;
}
.stepperItem {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.stepperTitle {
  position: absolute;
  font-weight: bold;
  top: 50px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
.stepperSecondaryTitle {
  position: absolute;
  top: 120px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.stepperDescription {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

.negativeIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -61%);
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.negativeIconContainer {
  position: relative;
  text-align: center;
}
.warningIconContainer {
  width: 100px;
  position: relative;
  text-align: center;
  cursor: help;
  &:hover {
    .uploadDocumentsTooltip {
      display: block;
    }
    .triangle {
      display: block;
    }
  }
}
.warningIconStipulationsContainer {
  width: 100px;
  position: relative;
  text-align: center;
  cursor: help;
  &:hover {
    .uploadStipulationsTooltip {
      display: block;
    }
    .triangle {
      display: block;
    }
  }
}
.warningIconFundingHeldContainer {
  width: 100px;
  position: relative;
  text-align: center;
  cursor: help;
  &:hover {
    .fundingHeldTooltip {
      display: block;
    }
    .triangle {
      display: block;
    }
  }
}
.dealerFundedIconContainer {
  width: 100px;
  position: relative;
  text-align: center;
  cursor: help;
  &:hover {
    .dealerFundedTooltip {
      display: block;
    }
    .triangle {
      display: block;
    }
  }
}
.triangle {
  display: none;
  width: 0;
  height: 0;
  border-style: solid;
  top: 30px;
  right: 27px;
  position: absolute;
  border-width: 0 17.5px 20px 17.5px;
  border-color: transparent transparent $blue transparent;
  left: 0;
  right: 0;
  margin: auto;
}
.uploadDocumentsTooltip {
  background: $blue;
  position: absolute;
  display: none;
  top: 50px;
  width: 200px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px 40px;
  border-radius: 20px;
  text-align: left;
  ol {
    padding: 0;
    list-style: decimal;
    li {
      font-size: 16px;
      p {
        margin: 0px;
      }
    }
  }
}
.deadTooltip {
  background: $blue;
  position: absolute;
  display: none;
  top: 50px;
  width: 200px;
  height: 200px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px;
  border-radius: 20px;
  text-align: left;
}
.uploadStipulationsTooltip {
  background: $blue;
  position: absolute;
  display: none;
  top: 50px;
  width: 200px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px 40px;
  border-radius: 20px;
  text-align: left;
  ol {
    padding: 0;
    list-style: decimal;
    li {
      font-size: 16px;
      p {
        margin: 0px;
      }
    }
  }
}
.fundingHeldTooltip {
  background: $blue;
  position: absolute;
  display: none;
  top: 50px;
  width: 400px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px 40px;
  border-radius: 20px;
  text-align: left;
  ol {
    padding: 0;
    list-style: decimal;
  }
}
.dealerFundedTooltip {
  background: $blue;
  position: absolute;
  display: none;
  top: 50px;
  width: 400px;
  z-index: 100;
  left: -90%;
  transform: translateX(-50%);
  color: white;
  padding: 10px 40px;
  border-radius: 20px;
  text-align: left;
  ol {
    padding: 0;
    list-style: decimal;
  }
}
.active {
  display: block;
}
.activeFlex {
  display: flex;
  flex-direction: column;
}
.link {
  font-weight: bold;
  font-style: italic;
  text-decoration: none;
  color: $primaryDark;
  text-transform: uppercase;
}
