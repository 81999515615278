.header {
  font-weight: 700;
  font-size: 23px;
  line-height: 40px;
  /* or 174% */

  text-transform: uppercase;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  /* or 174% */
  margin: 0px;
  text-transform: capitalize;
  flex: 1;
  color: #000000;
}

.text {
  margin: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  flex: 2;
}

.digits {
  margin: 0px;

  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
  /* or 182% */
  flex: 1;
  text-transform: capitalize;

  /* #D9D9D9 */

  color: #6b97f6;
}

.gridItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
