@import "src/theme";

.container {
  overflow: auto;
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(243, 243, 243);
  padding: 10px;
  border-radius: 5px;
}
.headerTitle {
  color: $blue;
  font-size: 25px;
}
.headerControlsContainer {
  display: flex;
  align-items: center;
}
.headerSelectContainer {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}
.blueTitle {
  font-weight: bold;
  font-size: 20px;
  color: $blue;
  margin: 20px 0px 20px 10px;
}
.chartContainer {
  margin-top: 20px;
  border: 3px solid rgb(243, 243, 243);
}
.detailedProfitContainer {
  display: flex;
  column-gap: 10px;
  margin-top: 20px;
}
.detailedProfitBoxContainer {
  flex-grow: 1;
  border: 3px solid rgb(243, 243, 243);
  border-radius: 5px;
  padding: 20px;
}
.detailedProfitBoxTitle {
  font-weight: bold;
  font-size: 20px;
  color: $blue;
  margin-bottom: 20px;
}
.detailedProfitBoxDataContainer {
  display: flex;
  align-items: baseline;
}
.detailedProfitBoxMainData {
  margin-right: 10px;
  font-weight: bold;
  font-size: 20px;
}
.performanceContainer {
  display: flex;
  margin-top: 30px;
  gap: 10px;
}
.performanceBoxAndArrowContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.performanceBoxContainer {
  flex-grow: 1;
  border-radius: 5px;
  padding: 20px;
}
.performanceBoxTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.performanceBoxData {
  font-weight: bold;
  font-size: 16px;
  color: black;
  margin-bottom: 20px;
  text-align: center;
}
/* Define the keyframes for the flash effect */
@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.performanceArrowContainerAnimate {
  animation: flash 0.5s ease-in-out 3;
}
.performanceArrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #6b97f6;
  background-color: #6b97f6;
  margin-left: 10px;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 40px;
  opacity: 1;
}
.performanceArrowData {
  font-weight: bold;
  color: #fff;
  font-size: 22px;
  margin-bottom: 5px;
  text-align: center;
  max-width: 100px;
  word-break: break-word;
}
