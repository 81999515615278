.root {
  width: 100%;
  margin-top: 25px;
  margin: 10px;
}
.formContainer {
  margin-top: 50px;
}

.deleteUser {
  position: absolute;
  top: 16px;
  right: 5px;
  flex-direction: row;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
}
.heading {
  font-size: 22px;
  font-weight: 700;
}
.description {
  font-size: 18px;
  font-weight: 400;
}

.smallHeading {
  font-size: 18px;
  font-weight: 500;
}

.checkboxContainer {
  display: flex;
  margin: 5px;
}

.border {
  border: solid 1px;
  border-radius: 10px;
  border-color: #8080804a;
  padding: 10px !important;
  margin: 5px !important;
  background: white;
}
.permission {
  font-weight: 600;
  display: flex;
  background-color: white;
  border: solid 1px #6b97f6;
  padding: 10px;
  margin: 10px 5px;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
}

.permissionSelected {
  display: flex;
  font-weight: 600;
  background-color: #6b97f6;
  border: solid 1px #6b97f6;
  padding: 10px;
  margin: 10px 5px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.permissionsContainer {
  background-color: #80808026;
  border-radius: 8px;
}
