@import "src/theme";

.container {
  background: $lightBlueSecondary;
}

.logo {
  position: fixed;
  top: 30px;
  left: 30px;
}
