@import "src/theme";

.root {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  display: flex;
  align-items: center;
  margin: 20px;
  cursor: pointer;
  &:hover {
    color: $blue;
  }
}
