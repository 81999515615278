@import "src/theme";

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 5px 0px;
}
.root {
  background: #f3f3f3;
  border-radius: 5px;
  padding: 17px 20px;
}

.documentsToBeSigned {
  outline: none;
  &:focus {
    border: 2px solid $blue;
    animation: blink 1s steps(100, start) 2;
    margin: -2px !important;
  }
}
@keyframes blink {
  50% {
    border-color: #fff;
  }
}
