@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  display: inline-block;
  position: relative;
  top: 2px;
}
.spinnerContainer {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  > h2 {
    color: #6b97f6;
    display: inline-block;
    margin-left: 20px;
  }
}

.spinnerContainerFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h2 {
    color: #6b97f6;
    display: inline-block;
  }
}

.loadingContainer {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
