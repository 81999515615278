@import "src/theme";

.container {
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 40px;
  color: $primaryDark;
  margin: 40px 0px 10px 0px;
}

//  START TABLE STYLES

.tableContainer {
  width: 100%;
  overflow: auto;
}

.tableTitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 33px;
  color: $primaryDark;
  //padding-left: 30px;
  margin: 0px 0px 10px 0px;
}

.table {
  line-height: 28px;
  padding: 0;
  margin: 0 0 10px 0;
  min-height: 200px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.tableHead {
  white-space: nowrap;
}

.tableColumnHeader {
  padding-left: 30px;
  font-weight: bold;
  line-height: 23px;
  flex-grow: 1;
  text-align: left;
  font-size: 20px;
}
.tableColumnHeader:last-child {
  text-align: right;
  padding-right: 20px;
}

.tableRow {
  line-height: 28px;
  padding: 12px 0px;
  white-space: nowrap;
  flex-grow: 1;
  min-height: auto;
  background: #f3f3f3;
  border-radius: 6px;

  td:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  td:last-child {
    border-radius: 0px 5px 5px 0px;
    text-align: center;
  }
  &:hover {
    background-color: $lightBlueSecondary;
    color: $evenDarkerGray;
    #icon {
      color: $evenDarkerGray;
    }
  }
}

.tableColumn {
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 17px;
  padding-left: 30px;
  flex-grow: 1;
  text-align: left;
}

.tableColumnContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tableColumnActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: 30px;
}

// END TABLE STYLES

// START FORM STYLES
.settingsForm > div > h2 {
  margin-bottom: 20px;
  font-size: 22px;
}
.formButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
// END FORM STYLES
