@import "src/theme";

.container {
  padding: 10px;
  input {
    // margin: 5px;
    border-radius: 7px;
  }
  p {
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    color: $primaryDark;
  }
}
