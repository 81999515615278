@import "src/theme";

.container {
  padding: 0px 200px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  h1 {
    font-weight: 700;
    font-size: 100px;
    line-height: 117px;
    text-align: center;
    text-transform: uppercase;
    color: $blue;
  }
  p {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: $evenDarkerGray;
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    flex: 1 1 0%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.smokePath {
  animation: flames 2000ms linear infinite normal forwards;
}

@keyframes flames {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.1;
  }
  10% {
    opacity: 0.3;
  }
  15% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.6;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.8;
  }
  45% {
    opacity: 0.9;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0.9;
  }
  60% {
    opacity: 0.8;
  }
  65% {
    opacity: 0.7;
  }
  70% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.4;
  }
  85% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.2;
  }
  91.5% {
    opacity: 0.17;
  }
  95% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}
