@import "src/theme";

.badgeContainer {
  border-radius: 50%;
  background: $blue;
  font-weight: 700;
  font-size: 13px;
  color: #fff;
  line-height: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
