@import "src/theme";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.root {
  margin: 20px 275px;
}
.header {
  font-weight: 700;
  font-size: 35px;
  line-height: 53px;
  text-transform: capitalize;
  color: $blue;
}

.paragraph {
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: black;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.button {
  width: 65%;
  height: 55px;
}
.secondaryContainer {
  padding: 30px;
  background: $lightBlue;
  overflow-y: auto;
  height: 40vh;
  margin-bottom: 10px;
}
.secondaryHeader {
  color: $blue;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-transform: capitalize;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: $blue;
  margin-bottom: 10px;
}
.secondaryTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
}
.dialog {
  padding: 0px 20px;
}
