@import "src/theme";

.container {
  padding-left: 10px;
  border-left: 3px solid $blue;
}
.title {
  gap: 10px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  text-transform: capitalize;
  margin-bottom: 20px;
  color: $primaryDark;
}
