@import "src/theme";
.table {
  line-height: 28px;
  padding: 0;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.columnHeader {
  padding-left: 15px;
  font-weight: bold;
  line-height: 23px;
  flex-grow: 1;
  text-align: left;
  font-size: 20px;
}
.columnContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.column {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 17px;
  padding-left: 15px;
  flex-grow: 1;
  text-align: left;
}
.actions {
  width: 50px;
  padding-right: 14px;
}
.row {
  line-height: 28px;
  padding: 12px 0px;
  white-space: nowrap;
  flex-grow: 1;
  min-height: auto;
  background: #f3f3f3;
  border-radius: 6px;

  td:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  td:last-child {
    border-radius: 0px 5px 5px 0px;
    text-align: center;
  }
  &:hover {
    background-color: $lightBlueSecondary;
    color: $evenDarkerGray;
    #icon {
      color: $evenDarkerGray;
    }
  }
}
.footerRow {
  line-height: 28px;
  padding: 12px 0px;
  white-space: nowrap;
  flex-grow: 1;
  min-height: auto;
  background: #e6e4e4;
  border-radius: 6px;

  td:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  td:last-child {
    border-radius: 0px 5px 5px 0px;
    text-align: center;
  }
  &:hover {
    background-color: $lightBlueSecondary;
    color: $evenDarkerGray;
    #icon {
      color: $evenDarkerGray;
    }
  }
}
.notFound {
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.tableContainer {
  width: 100%;
}

.tableHead {
  white-space: nowrap;
}
