@import "src/theme";

.title {
  margin: 40px 0px 10px 0px;
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 40px;
  color: $primaryDark;
  padding-left: 30px;
}
.container {
  width: 100%;
  // display: flex;
  overflow: auto;
}
