@import "src/theme";
.recentApplicantonContainer {
  overflow: auto;
  width: 100%;
}
.recentApplications {
  padding-left: 10px;
  border-left: 3px solid $warning;
}
.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 20px;
  color: $primaryDark;
}
.status {
  background: $lightBlue;
  border-radius: 5px;
}
.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
