@import "src/theme";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  row-gap: 20px;
  font-weight: 500;
  position: absolute;
  top: 54px;
  right: 320px;
}

.ticketsMenu {
  position: absolute;
  background: white;
  top: 100px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 9999;
}

.ticketsMenuItem {
  cursor: pointer;
  padding: 15px;
  &:hover {
    background: $gray;
  }
}
