.authInput,
.authButton {
  height: 64px !important;
  margin: 10px 0px;
  width: 100%;
}
.signOut {
  float: right;
  color: #2b2b2b;
  text-decoration: underline;
}
.signOut:hover {
  cursor: pointer;
}
