.root {
  width: 100%;
  margin-top: 25px;
  margin: 10px;
}
.formContainer {
  margin-top: 50px;
}

.deleteUser {
  position: absolute;
  top: 16px;
  right: 5px;
  flex-direction: row;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
}
