@import "src/theme";

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.question {
  text-align: left;
}

.imagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.takenImagePreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid $darkGray;
}
.takenImagePreviewHeader {
  font-style: italic;
  color: $blue;
}
