@import "src/theme";

.noteCreator {
  font-size: 12px;
  font-style: italic;
  color: $darkerGray;
  margin-left: auto;
  padding-right: 5px;
}

.noteContainer {
  width: 100%;
  height: 470px;
  overflow-y: auto;
  margin-bottom: 10px;
}
.senderNote {
  padding: 10px;
  background-color: $gray;
  border-radius: 9px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
  margin-left: auto;
}
.receiverNote {
  padding: 10px;
  background-color: $lightBlue;
  border-radius: 9px;
  font-size: 18px;
  word-wrap: break-word;
  font-weight: 600;
  color: #000;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
}
