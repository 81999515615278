@import "src/theme";

.dashed {
  border: 2px dashed $lightBlue;
}

.dropZone {
  text-align: center;
  // padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
}

.dropZoneContainer {
  flex: 1;
  overflow: auto;
  gap: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  // width: 100%;
}

.info {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
}

.button {
  padding: 10px;
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
}

.circularProgressBar {
  flex: 1;
  align-items: flex-end;
}
