@import "src/theme";

.container {
  background: #ffffff;
  border-radius: 7px;
  align-items: center;
  display: flex;
  padding: 10px;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;

  input[type="radio"] {
    transform: scale(1.3);
    margin-right: 8px;
  }
}

.description {
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  padding: 10px;
}
.autofilled {
  //border: 2px dashed rgb(255, 96, 123);
}
.autofilledLabel {
  color: rgb(255, 96, 123);
  font-size: 11px;
  position: relative;
  top: -4px;
}
