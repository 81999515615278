@import "src/theme";

.iconContainer {
  background: $lightBlueTertiary;
  border-radius: 5px 0px 0px 5px;
  padding: 10px;
  width: 35px;
  display: flex;
  align-items: center;
}

.container {
  width: 30%;
  height: 54px;
  display: flex;
  position: relative;
}
.textField {
  flex: 11;
}
.icon {
  flex: 1;
}

.tableContainer {
  position: absolute;
  top: 100%;
  z-index: 9999;
  width: 35vw;
  background: #ffffff;
  margin-top: 10px;
  box-shadow: 1px 10px 16px -3px rgba(133, 131, 133, 1);
  border-radius: 5px;
  left: 0;
  max-height: 30vh;
  overflow: auto;
}
.topBorders {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  tr {
    height: 35px;
    &:hover {
      background: $gray;
      cursor: pointer;
    }
  }
  thead {
    background: $gray;

    height: 40px;
  }
  tr:nth-child(1) {
    border-top: solid 0.5px $darkGray;
  }
  tr:not(:last-child) {
    border-bottom: solid 0.5px $darkGray;
  }
  td,
  th {
    padding-left: 5px;
    width: 25%;
  }
}
.headerIcon {
  margin-left: 5px;
}
.tableHeaderContainer {
  background: $lightBlueTertiary;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 18px;
}
