@import "src/theme";

.logoContainer {
  width: 100%;
  text-align: center;
  padding-top: 59.5px;
  padding-bottom: 80px;
}
.activeMenuItem {
  //box-shadow: inset 10px 000 $blue;
  > a {
    color: $blue !important;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.activeIndicator {
  background-color: $blue;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
}
.activeMenuItemWithEntities {
  background: $gray;
}
.menu > .menuItem:first-child {
  padding-top: 0px;
}
.menuItem {
  padding: 15px 0px;
  // padding-left: 20px;
  font-size: 17px;
  position: relative;
  > a {
    text-decoration: none;
    color: $darkerGray;
    display: block;
    padding: 10px;
    padding-left: 100px;
    font-weight: 700;
    font-family: $secondMain;
    font-size: 17px;
    position: relative;
    &:hover {
      color: black;
    }
    > svg {
      position: absolute;
      font-size: 35px;
      margin: auto;
      margin-right: 10px;
      top: 0px;
      bottom: 0px;

      left: 40px;
    }
  }
}
.menuItemInfo {
  cursor: pointer;
  text-decoration: none;
  color: $darkerGray;
  display: block;
  padding: 10px;
  padding-left: 100px;
  font-weight: 700;
  font-family: $secondMain;
  font-size: 17px;
  position: relative;
  &:hover {
    color: black;
  }
  > svg {
    position: absolute;
    font-size: 35px;
    margin: auto;
    margin-right: 10px;
    top: 0px;
    bottom: 0px;

    left: 40px;
  }
}
.menuItemSecondary {
  padding: 15px 0px;
  font-size: 17px;
  position: relative;
  padding-left: 20px;
  > a {
    text-decoration: none;
    color: $darkerGray;
    display: block;
    padding: 10px;
    padding-left: 100px;
    font-weight: 700;
    font-family: $secondMain;
    font-size: 17px;
    position: relative;
    &:hover {
      color: black;
    }
    > svg {
      position: absolute;
      font-size: 35px;
      margin: auto;
      margin-right: 10px;
      top: 0px;
      bottom: 0px;

      left: 40px;
    }
  }
}
.search {
  display: flex;
  margin-top: 55px;
  margin-left: 20px;
  justify-content: space-between;
}
