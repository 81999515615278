@import "src/theme";

.input {
  width: 20px;
  outline: none;
  &:focus {
    border: 3px solid white;
    background: white;
  }
  &:hover:not(:disabled) {
    box-shadow: 0px 0px 4px -3px black;
  }
  &:disabled {
    background: rgb(223, 223, 223);
    border-color: rgb(223, 223, 223);
  }
}

.inputContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.label {
  text-transform: capitalize;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;

  z-index: 4;
}
