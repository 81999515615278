@import "src/theme";
.container {
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 80vh;
  height: 100%;
}

.Spinner-Viewbox-rotating {
  animation: 2s linear infinite both spin;
}
.Spinner-Viewbox-paused {
  animation-fill-mode: forwards;
  animation-play-state: paused;
}
.Spinner-Circle {
  animation: 2s ease-in-out infinite both chase;
  fill: transparent;
  stroke: $darkGray;
  stroke-dasharray: 100;
  stroke-linecap: round;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}
.Spinner-Circle-paused {
  fill: transparent;
  stroke: $darkGray;
  stroke-dasharray: 100;
  stroke-linecap: round;
  stroke-width: 10px;
  transform-origin: 50% 50%;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: fill;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}
.Spinner-Check {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  path {
    transform: translate(25%, 25%) scale(2.5);
    height: 1000px;
    width: 100px;
    fill: $darkGray;
  }
}
.Spinner-Check-shown {
  position: absolute;
  top: 0;

  left: 0;
  display: none;
  path {
    transform: translate(25%, 25%) scale(2.5);
    height: 1000px;
    width: 100px;

    fill: #15c969;
  }
  display: block;
  animation-name: grow;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}
.Spinner {
  position: relative;
  height: 100px;
  width: 100px;
}
.successMessageContainer {
  display: flex;
  margin: 10px;
  width: 50%;
  flex-direction: column;
  text-align: center;
  animation-name: scaleText;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}
.successMessage {
  font-weight: bold;
  color: $darkGray;
  display: flex;
  font-size: 24px;
}
.successMessageFooter {
  font-style: italic;
  font-size: 14px;
  color: $darkGray;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes chase {
  0% {
    stroke-dashoffset: 99;
    transform: rotate(0deg);
  }

  50% {
    stroke-dashoffset: 50;
    transform: rotate(180deg);
  }

  100% {
    stroke-dashoffset: 99;
    transform: rotate(360deg);
  }
}

@keyframes fill {
  0% {
    stroke-dashoffset: 99;
    transform: rotate(0deg);
  }

  50%,
  100% {
    stroke-dashoffset: 0;
    transform: rotate(360deg);
  }
}

@keyframes scaleText {
  0% {
    transform: scale(0);
  }

  50%,
  100% {
    transform: scale(1);
  }
}

@keyframes grow {
  0% {
    transform: scale(0) rotate(0deg);
  }

  50%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}
