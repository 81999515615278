.button {
  background: linear-gradient(90deg, #f2798e -0.11%, #708ec9 99.89%);
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 18px;
  padding: 2px 15px;
  font-weight: bold;
  margin-right: 20px;
  z-index: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  span {
    font-size: 30px;
    position: relative;
    top: 3px;
  }
}
