@import "src/theme";

.container {
  background: $gray;
  border-radius: 10px;
  height: auto;
  padding: 10px !important;
  margin: 10px !important;
  color: black;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 25px;
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
div.stepTitle {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  padding-left: 20px;
  svg {
    display: inline-block;
    margin-right: 30px;
    width: 50px;
  }
  svg:nth-child(2) {
    width: 100px;
  }
  p {
    display: inline-block;
  }
}
.active {
  color: $pink;
}
.completed {
  color: $blue;
}
.inactive {
  color: $darkGray;
}

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 40px;
  color: $primaryDark;
  padding-left: 30px;
}

.mainContainer {
  padding: 10px;
  // width: 100%;
}
.buttonContainer {
  display: flex;
  flex-direction: row;

  > button {
    width: 242px;
    margin-right: 20px;
  }
}

.completedIcon {
  margin-left: auto;
}

.actions {
  margin-top: 30px;
  margin-bottom: 10px;
}

.stepContainer {
  padding: 0px 30px !important;
}
.scanText {
  font-size: 20px;
  font-weight: 600;
}
.scanContainer {
  padding: 20px I !important;
  margin: 10px !important;
  gap: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanItem {
  flex: 1;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 18px !important;
}
.scanTitle {
  color: $darkerGray;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
.scanText {
  color: $blue;
}
.scanButton {
  width: 80%;
  margin-top: auto;
}
.instructions {
  font-size: 18px;
  color: $blue;
  font-weight: 700;
  cursor: pointer;
}

ol li {
  padding: 8px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
img {
  height: 400px;
  max-width: 100%;
  border-radius: 9px;
}

.radioContainer {
  background: $gray;
  border-radius: 10px;
  height: auto;
  padding: 10px !important;
}
.radio {
  font-size: 25px;
  font-weight: 700;
}
