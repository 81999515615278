/* OrDivider.css */
@import "src/theme";

.divider {
  background-color: $darkerGray; /* Change color as needed */
  width: 2px;
  margin: 0 5px;
  height: 40%;
}

.dividerContainer {
  height: 100%;
  gap: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.dividerText {
  color: $darkerGray; /* Change color as needed */
  margin: 0 5px;
  font-size: 32px;
  font-weight: 900;
}
