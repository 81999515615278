@import "src/theme";
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  position: absolute;
  top: 54px;
  right: 270px;
}
.notificationsContainer {
  overflow-y: auto;
  overflow-x: hidden;
}
.defaultTextContainer {
  width: 592px; //30.83vw;
  display: flex;
  height: 100%;
  font-weight: 500;
  font-size: 24px;
  color: $darkerGray;
  justify-content: center;
  align-items: center;
}
.notificationsMenu {
  position: absolute;
  background: white;
  height: 66vh;
  top: 43px;
  right: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: space-between;
}
.notificationsMenu .notificationContainer:not(:last-child) {
  border-bottom: solid $gray;
  border-width: thin;
}
.notificationContainer {
  display: flex;
  flex-direction: row;
  padding: 15px;
  position: relative;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $gray;
  }
}
.newBadge {
  width: 15px;
  height: 15px;
  min-width: 15px;
  border-radius: 100%;
  background: #00a1df;
}

.iconCircle {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #75ff7538;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.iconCircleLoading {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #9e9ca738;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.notificationTitle {
  font-size: 16px;
}
.notificationDescription {
  min-width: 400px;
  font-size: 14px;
  color: $evenDarkerGray;
  font-weight: normal;
}

.notificationBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
}
.notificationTime {
  font-weight: normal;
  font-size: 14px;
  color: $evenDarkerGray;
  min-width: 100px;
  padding-right: 10px;
  text-align: center;
}
.deleteNotificationButton {
  position: absolute;
  right: 0;
  top: 0;
}
.controlButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 10px;
}

.badgeContainer {
  border-radius: 50%;
  background: red;
  font-weight: bold;
  font-size: 10px;
  color: #fff;
  width: 17px;
  height: 17px;
  display: flex;
  position: absolute;
  right: 8px;
  top: 4px;
  justify-content: center;
  align-items: center;
}
.notificationContainerHeader {
  font-size: 24px;
  font-weight: bold;
  padding: 10px 10px 10px 15px;
  border-bottom: solid $gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: thin;
}
.notificationContainerForDeletion {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 15px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: removedNotificationAnimation;
}
@keyframes removedNotificationAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
    opacity: 0;
  }
  51% {
    opacity: 0;
    transition: 1;
    max-height: 100px;
    padding: 15px;
  }
  100% {
    padding: 0px;
    max-height: 0;
    opacity: 0;
  }
}
.clearAllButton {
  display: flex;
  align-items: center;
  padding: 5px;
  background: #ffffff;
  font-size: 15px;
  gap: 5px;
  border: none;
  font-family: $main;
  font-weight: 400;
  border-radius: 5px;
  &:hover {
    box-shadow: 0px 0px 6px -2px $darkerGray;
  }
  cursor: pointer;
}
.loadMoreText {
  font-weight: 500;
  text-decoration: underline;
  font-size: 14px;
  color: $blue;
}
////////////////////////// LOADING ////////////////////////////////////

.newBadgeLoading {
  width: 15px;
  height: 15px;
  min-width: 15px;
  border-radius: 100%;
  background: $darkerGray;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

//titlePlaceholder start
.titlePlaceholder {
  margin: 0 auto;
  border-radius: 5px;
  min-height: 15px;
  background-color: #eee;
}
.animatedTitlePlaceholder {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  border-radius: 5px;

  height: 15px;
  position: relative;
}
//titlePlaceholder end

//descriptionPlaceholder start
.descriptionPlaceholder {
  margin: 0 auto;
  min-height: 30px;
  background-color: #eee;
  border-radius: 5px;
}
.animatedDescriptionPlaceholder {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  border-radius: 5px;

  height: 30px;
  position: relative;
}
//descriptionPlaceholder end

.deleteNotificationButtonLoading {
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
}
