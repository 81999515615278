@import "src/theme";

.iconButton {
  border-radius: 10px;
  padding: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: $blue;
    transition: all 0.1s linear;
    transform: scale(1.03);
  }
}
.flex {
  display: flex;
  align-items: center;
}
.blueCircle {
  color: $blue;
  border-radius: 100%;
  border: 1px solid $blue;
  padding: 10px;
  width: 39px;
  height: 39px;
  cursor: pointer;
  &:hover {
    color: white;
    background: $blue;
    transition: all 0.1s linear;
    transform: scale(1.03);
  }
}
