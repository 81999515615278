@import "src/theme";

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 5px 0px;
}
.container {
  background: #f3f3f3;
  border-radius: 5px;
  padding: 10px;
  height: 212px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button {
  width: 50%;
  margin-top: 40px;
}

.form {
  padding: 10px;
  background: $gray;
}
