@import "src/theme";

.root {
  font-weight: 400;
  font-size: 21px;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin: 20px;
  cursor: pointer;
  &:hover {
    color: $pink;
  }
  p {
    margin: 0;
  }
}
