.label {
  font-weight: 500;
  font-size: 20px;
  margin: 0px 13px;
  flex: 1;
}
.data {
  flex: 1;
  font-weight: 300;
  font-size: 19px;
}

.dataContainer {
  display: flex;
}

.button {
  margin-top: 40px;
}
.coApplicantButton {
  position: absolute;
  bottom: 0;
  margin: 18px 0px;
}
