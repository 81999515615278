@import "src/theme";

.input {
  display: block;
  flex: 1;
  border: 3px solid white;
  border-radius: 10px;
  resize: none;
  padding: 8px 0px 8px 5px;
  background: white;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: $evenDarkerGray;
  &:focus {
    border: 3px solid white;
    background: white;
  }

  &:disabled {
    background: rgb(223, 223, 223);
    border-color: rgb(223, 223, 223);
  }
  // &:invalid {
  //   border: 3px solid red;
  // }
}
.inputWithStartIcon {
  padding: 8px 0px 8px 60px;
}
.inputContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}
.icon {
  display: inline-block;
  //top: 20px;
  color: $blue;
  position: absolute;
  width: 24px;
  height: 27px;
  text-align: center;
  > svg {
    width: 100%;
    height: 100%;
  }
}
.left {
  left: 20px;
}
.right {
  right: 20px;
}

.label {
  text-transform: capitalize;
  margin-left: 15px;
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  position: absolute;
  top: -13px;
  z-index: 999;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
