@import "src/theme";

.container {
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
}
.default {
  background-color: #ffffff;
}
.blue {
  background-color: $lightBlueSecondary;
}

.title {
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  margin-bottom: 10px;
}

.label {
  font-weight: 300;
  font-size: 19px;
  margin: 0px 0px;
  flex: 1;
}
.data {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
  margin: 0px 0px;
}

.dataContainer {
  display: flex;
}
