@import "src/theme";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 600px;
  min-height: 200px;
  margin-top: 30px;
}
.itemContainer {
  display: flex;
  border: 1px solid $darkGray;
  border-radius: 5px;
  width: 100%;
  gap: 10px;
  flex-direction: column;
}
.itemContainerHalf {
  display: flex;
  border-radius: 5px;
  width: 100%;
  gap: 10px;
  flex-direction: row;
}
.itemHeader {
  padding: 5px;
  font-weight: bold;
  font-size: 18px;
  color: $evenDarkerGray;
  text-align: center;
}
.description {
  padding: 5px 5px 5px 15px;
  color: $evenDarkerGray;
}
.image {
  max-width: 900px;
  height: auto;
  padding: 5px;
}
.link {
  padding: 5px;
  text-align: center;
}
.notesContainer {
  padding-left: 10px;
}
.notesHeader {
  padding: 10px 0px 0px 10px;
  font-weight: bold;
  font-size: 20px;
  color: $evenDarkerGray;
}
