@import "src/theme";

.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: 700;
    text-align: center;
    color: $blue;
  }
}
