@import "src/theme";

.input {
  width: 100%;
  // height:100%;
  display: block;
  flex: 1;
  border: 3px solid white;
  border-radius: 10px;
  padding: 8px 0px 8px 5px;
  background: white;
  outline: none;
  font-size: 19px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  &:focus {
    border: 3px solid white;
    background: white;
  }
  &:hover:not(:disabled) {
    box-shadow: 0px 0px 6px -2px black;
  }
  &:disabled {
    background: rgb(223, 223, 223);
    border-color: rgb(223, 223, 223);
  }
}
.inputWithStartIcon {
  padding: 8px 0px 8px 60px;
}
.inputContainer {
  width: 100%;
  display: flex;
  // margin-bottom: 12.5px;
  // margin-top: 12.5px;
  position: relative;
}
.icon {
  display: inline-block;
  top: 20px;
  left: 20px;
  color: $blue;
  position: absolute;
  width: 24px;
  height: 27px;
  text-align: center;
  > svg {
    width: 100%;
    height: 100%;
  }
}
.label {
  z-index: 100;
  margin-left: 15px;
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  position: absolute;
  top: -13px;
}
.autofilled {
  //border: 2px dashed rgb(255, 96, 123);
}
.autofilledLabel {
  color: rgb(255, 96, 123);
}
