@import "src/theme";

.expandableHeader {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;

  &:hover {
    background: $gray;
  }
}

.expandableContainer {
  border-left: 3px solid $green;
  padding-left: 10px;
  margin: 20px 0;
}
.expandableContent {
  transition: height 0.5s ease-out;
  overflow: hidden;
}
