// Colors

$primaryDark: #29282e;
$secondaryDark: #373739;
$blue: #6b97f6;
$lightBlue: #e7eeff;
$lightBlueTertiary: #b7cdff;
$lightBlueSecondary: #e7eeff;
$pink: #eb8190;
$lightPink: #efabb8;
$gray: #f3f3f3;
$darkGray: #a5aec1;
$darkerGray: #9e9ca7;
$evenDarkerGray: #5e5d65;
$secondaryGray: #c7c6cb;
$primaryGradientColor: linear-gradient(90deg, #f2798e -0.11%, #708ec9 99.89%);
$secondaryGradientColor: linear-gradient(
  180deg,
  #b7cdff -41.43%,
  rgba(183, 205, 255, 0) 226.29%
);
$blueGradient: linear-gradient(90deg, #6b97f6 -25%, #b7cdff 135%);
$green: #17db71;
$success: #60b527;
//Fonts

$main: "Roboto", "Times", "serif";
$secondMain: "Roboto Condensed", "Times", "serif";
$warning: #ffbb6c;
