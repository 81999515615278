@import "src/theme";

.container {
  background: $gray;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 430px;
  outline: none;

  &:focus {
    margin: -2px !important;
    border: 2px solid $blue;
    animation: blink 1s steps(100, start) 2;
  }
}
.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-transform: capitalize;
  margin: 5px 0px;
}

.arrow {
  margin-top: 10px;
  border-radius: 50%;
  border: 5px solid $lightBlue;
  padding: 10px 20px;
}

.progress {
  margin-top: 10px;
  border-radius: 50%;
  border: 5px solid $blue;
  padding: 10px 20px;
}

.item {
  // margin: 15px 0px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid black;
  }
  cursor: pointer;
}

.documentName {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.docs {
  overflow: auto;
  height: 100%;
}
.docsTiny {
  overflow: auto;
  height: 70%;
}
.innerContainer {
  height: 343px;
}

.uploadProgress {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $blue;
}

.progressBar {
  height: 5px !important;
  margin: 0px !important;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}
