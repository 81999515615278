@import "src/theme";

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 40px;
  color: $primaryDark;
  padding-left: 30px;
  margin: 40px 0px 10px 0px;
}
.container {
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  background-color: $lightBlueSecondary;
  height: 100%;
}

.titleSecondary {
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  margin-bottom: 10px;
}

.root {
  width: 100%;
}

.tableContainer {
  width: 100%;
  overflow: auto;
}

.bold {
  font-weight: "bold";
  color: $blue;
}

.prediction {
  font-weight: 300;
  font-size: 20px;
  color: black;
  margin: 5px 0px;
  flex: 1 1;
}
.footer {
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  color: $blue;
}
