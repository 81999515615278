@import "src/theme";

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 40px;
  color: $primaryDark;
  padding-left: 30px;
  margin: 40px 0px 10px 0px;
}
.container {
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  padding: 15px;
}
.default {
  background-color: #ffffff;
}
.blue {
  background-color: $lightBlueSecondary;
}

.titleSecondary {
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  margin-bottom: 10px;
}

.root {
  width: 100%;
}

.tableContainer {
  width: 100%;
  overflow: auto;
}
